
import CarouselContainer from './component/CarouselContainer';

function Carousel() {
    return (
        <>
            <div className="Carousel">
                <CarouselContainer />
            </div>
        </>
    );
}

export default Carousel;