import { Text } from '@chakra-ui/react';
import Logo1 from './logo3.png';

//import { chakra, useColorModeValue} from '@chakra-ui/react'
import * as React from 'react'

export const Logo = () => {
  return (
    <>
      <img src={Logo1} alt="Logo" width="46" height="30" />
      <Text fontSize="1.5rem" fontWeight="bold">МарКом-МС</Text>
    </>
  );
};
