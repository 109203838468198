import React, { Component } from "react";
import "./style404page.css";
import Footer from "../Footer/Footer";
import NavBar1 from "../NavBar/NavBar";


class Error404page extends Component {
    render() {
        return (
            <React.Fragment>

                <NavBar1 />

                <section className="error-page">
                    <h1 className="error-page-404"> 404 </h1>
                    <h1>Error page not found!</h1>
                </section>

                <footer>
                    <Footer />
                </footer>
            </React.Fragment>
        );
    }
}

export default Error404page;