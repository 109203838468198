import React from 'react';
import Footer from "../../Footer/Footer";
import smallBox1 from "./smallBox-1.jpg";
import ripped from "./ripped-cardboard-3.png";
import NavBar1 from "../../NavBar/NavBar";

export default function SmallBox() {
    return (
        <>
            <NavBar1 />

            <div className="px-4 pt-5 mt-5 text-center border-bottom">
                <h1 className="display-6 text-center pb-2">ЩАНЦОВИ ОПАКОВКИ</h1>
                <div className="col-lg-6 mx-auto">


                    <div className="overflow-hidden" >
                        <div className="container px-5">
                            <img src={smallBox1} className="img-fluid border rounded-3 shadow-lg mb-4" alt="Example2" width="700" height="500" loading="eager" />
                        </div>
                    </div>

                    <p className="mb-4">Щанцовите опаковки стават все по-популярни
                        и използвани заради липсата на необходимост от лепене или допълнителна
                        обработка за затваряне на кутията. Производители в сферата на козметиката,
                        фармацията, винарството, хранително-вкусовата промишленост, леката промишленост,
                        мебелното производство и др. разчитат все повече на този вид опаковане,
                        заради изключителната функционалност на щанцовите опаковки и лекотата при използването им.
                        Ние можем да изработим кутии за торти, пици, сладки, тавички, опаковки за осветителни тела,
                        кутии за електро части, обувки, дрехи, мебели и много други.</p>
                </div>
            </div>


            <img src={ripped} className="img-fluid" alt="cardboard" loading="eager" />

            <div className="border-bottom">
            </div>



            <Footer />

        </>
    )
}
