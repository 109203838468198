import React, { Component } from "react";
import { Link } from "react-router-dom";
//import Logo1 from "./logo192.png";
import Logo3 from "./logo3.png";
import "./styles.css";


class NavBar1 extends Component {
    render() {
        return (
            <React.Fragment>


                <nav className="navbar navbar-expand-lg navbar-light bg-light navbar-style">


                    <div className="container">
                        <Link className="navbar-brand" to="/">
                            <img src={Logo3} alt="MarKom-MC" width="46" height="30" className="d-inline-block align-text-top" />
                            MapKoм-MC
                        </Link>

                        {/* buton4eto za menuto pri malki ustroistva {/* */}
                        <button
                            className="navbar-toggler"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#navbarSupportedContent"
                            aria-controls="navbarSupportedContent"
                            aria-expanded="false"
                            aria-label="Toggle navigation">

                            <span className="navbar-toggler-icon"></span>
                        </button>

                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            {/* nashite razdeli 
                            // vmesto href="/#" izpolzvame to="/#" 
                            // i mesto <a> izpolzvame Link ot react-router-dom */}
                            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                                <li className="nav-item">
                                    <Link className="nav-link m-2" to="/">Начало</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link m-2" to="/products">Галерия</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link m-2" to="/materials">Материали</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link m-2" to="/contacts">Контакти</Link>
                                </li>

                            </ul>


                            <form className="d-flex">
                                {/* ikona za BG */}

                                {/* <Link className="link-dark ml-3" to="/">
                                    <svg xmlns="http://www.w3.org/2000/svg"
                                        width="24" height="24" className="flag-icons-bg"
                                        viewBox="0 0 640 480" fill-rule="evenodd" stroke-width="1pt">
                                        <path fill="#d62612" d="M0 320h640v160H0z" />
                                        <path fill="#fff" d="M0 0h640v160H0z" />
                                        <path fill="#00966e" d="M0 160h640v160H0z" />
                                    </svg>
                                </Link>

                                <div class="px-2">
                                </div> */}

                                {/* ikona za US */}
                                {/* <Link className="link-dark" to="/">
                                    <svg xmlns="http://www.w3.org/2000/svg"
                                        width="24" height="24" fill="currentColor" className="flag-icons-gb"
                                        viewBox="0 0 640 480">
                                        <path fill="#012169" d="M0 0h640v480H0z" />
                                        <path fill="#FFF" d="m75 0 244 181L562 0h78v62L400 241l240 178v61h-80L320 301 81 480H0v-60l239-178L0 64V0h75z" />
                                        <path fill="#C8102E" d="m424 281 216 159v40L369 281h55zm-184 20 6 35L54 480H0l240-179zM640 0v3L391 191l2-44L590 0h50zM0 0l239 176h-60L0 42V0z" />
                                        <path fill="#FFF" d="M241 0v480h160V0H241zM0 160v160h640V160H0z" />
                                        <path fill="#C8102E" d="M0 193v96h640v-96H0zM273 0v480h96V0h-96z" />
                                    </svg>
                                </Link> 

                                <div class="px-2">
                                </div>   */}

                                {/* !!!! Facebook icon link !!!*/}
                                <div className="hover-effect-icon">
                                    <a className="link-dark ml-3" href="https://www.facebook.com/%D0%9C%D0%B0%D1%80%D0%9A%D0%BE%D0%BC-%D0%9F%D1%80%D0%BE%D0%B8%D0%B7%D0%B2%D0%BE%D0%B4%D1%81%D1%82%D0%B2%D0%BE-%D0%BD%D0%B0-%D1%82%D1%80%D0%B0%D0%BD%D1%81%D0%BF%D0%BE%D1%80%D1%82%D0%BD%D0%B8-%D0%BE%D0%BF%D0%B0%D0%BA%D0%BE%D0%B2%D0%BA%D0%B8-%D0%BE%D1%82-%D0%B2%D0%B5%D0%BB%D0%BF%D0%B0%D0%BF%D0%B5-108863771691744" target="_blank" rel="noopener noreferrer">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                            fill="currentColor" className="bi bi-facebook" viewBox="0 0 16 16">
                                            <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z" />
                                        </svg>
                                    </a>
                                </div>


                            </form>

                        </div>
                    </div>


                </nav >

            </React.Fragment >
        );
    }
}

export default NavBar1;