import { chakra, Stack } from '@chakra-ui/react'
import * as React from 'react'
import { AspectRatio } from "@chakra-ui/react";



export const SubscribeForm = (props) => {
  return (
    <chakra.form {...props} >
      <Stack spacing="4">

        <AspectRatio maxW="400px" ratio={4 / 3}>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1483.6820098409844!2d25.549105937960345!3d41.94951560451564!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zNDHCsDU2JzU4LjMiTiAyNcKwMzInNjAuMCJF!5e0!3m2!1sbg!2sbg!4v1617726987650!5m2!1sbg!2sbg"
            alt="google map" title="GoogleMapView"
          />
        </AspectRatio>
      </Stack>
    </chakra.form>
  )
}
