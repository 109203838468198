import React from 'react';
import Footer from "../../Footer/Footer";
import pizzaBox2 from "./pizzaBox-3.jpg";
import ripped from "./ripped-cardboard-3.png";
import NavBar1 from "../../NavBar/NavBar";

export default function PizzaBox() {
    return (

        <>
            <NavBar1 />

            <div className="px-4 pt-5 mt-5 text-center border-bottom">
                <h1 className="display-6 text-center pb-2">ПОДЛОЖКИ ЗА ПИЦИ,ТОРТИ И Др.</h1>
                <div className="col-lg-6 mx-auto">


                    <div className="overflow-hidden" >
                        <div className="container px-5">
                            <img src={pizzaBox2} className="img-fluid border rounded-3 shadow-lg mb-4" alt="Example3" width="700" height="500" loading="eager" />
                        </div>
                    </div>

                    <p className="mb-4">Богата гама от подложки за пици, торти, сандвичи, палачинки, кошнички за кафе, картофки, пуканки и други.</p>

                </div>
            </div>

            <img src={ripped} className="img-fluid" alt="cardboard" loading="eager" />

            <div className="border-bottom">
            </div>



            <Footer />

        </>
    )
}
