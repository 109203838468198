import React, { Component } from "react";

import Gallery from "./Gallery";

//import p1 from './images/1.jpg';
// import p2 from './images/2.jpg';
// import p3 from './images/3.jpg';
// import p4 from './images/4.jpg';
// import p5 from './images/5.jpg';
// import p6 from './images/6.jpg';
//import "./styles.css";
import Footer from "../Footer/Footer";
import NavBar1 from "../NavBar/NavBar";

class Products extends Component {
    render() {
        return (
            <>
                <NavBar1 />

                <div className="mt-3">
                    {/*dinamichna Gallery */}

                    <Gallery />



                    <Footer />

                </div>
            </>
        );
    }
}

export default Products;