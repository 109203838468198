import React, { useState } from 'react';
import { AiOutlineClose } from 'react-icons/ai';

import './gallery.css';
import Img1 from './images/1.JPG';
import Img2 from './images/2.JPG';
import Img3 from './images/3.JPG';
import Img4 from './images/4.jpg';
import Img5 from './images/5.jpg';
import Img6 from './images/6.jpg';
import Img7 from './images/7.JPG';
import Img8 from './images/8.JPG';
import Img9 from './images/9.JPG';
import Img10 from './images/10.JPG';
import Img11 from './images/11.JPG';
import Img12 from './images/12.JPG';
import Img13 from './images/13.JPG';
import Img14 from './images/14.jpg';

const Gallery = () => {

    let data = [
        {
            id: 1,
            imgSrc: Img1,
        },
        {
            id: 2,
            imgSrc: Img2,
        },
        {
            id: 3,
            imgSrc: Img3,
        },
        {
            id: 4,
            imgSrc: Img4,
        },
        {
            id: 5,
            imgSrc: Img5,
        },
        {
            id: 6,
            imgSrc: Img6,
        },
        {
            id: 7,
            imgSrc: Img7,
        },
        {
            id: 8,
            imgSrc: Img8,
        },
        {
            id: 9,
            imgSrc: Img9,
        },
        {
            id: 10,
            imgSrc: Img10,
        },
        {
            id: 11,
            imgSrc: Img11,
        },
        {
            id: 12,
            imgSrc: Img12,
        },
        {
            id: 13,
            imgSrc: Img13,
        },
        {
            id: 14,
            imgSrc: Img14,
        },
    ];

    const [model, setModel] = useState(false);
    const [tempImgSrc, setTempImgSrc] = useState('');

    const getImg = (imgSrc) => {
        setTempImgSrc(imgSrc);
        setModel(true);
    }

    return (
        <>
            <div className={model ? "model open" : "model"}>
                <img src={tempImgSrc} alt="OpenImgInGallery" />
                <AiOutlineClose onClick={() => setModel(false)} />
            </div>
            <div className="gallery">
                {data.map((item, index) => {
                    return (
                        <div className="pics" key={index} onClick={() => getImg(item.imgSrc)}>
                            <img src={item.imgSrc} style={{ width: '100%' }} alt="imageGallery" />
                        </div>
                    )
                })}
            </div>
        </>
    )
}

export default Gallery;