import React from 'react';
import "./stylesThreeLayerVelpape.css";
import image_velpape_3 from "./images/3-plastno.png";
import image_velpape_5 from "./images/5-plastno.png";

export default function ThreeLayerVelpape() {
    return (
        <>
            <div id="three-five-layer-cont">

                <div className="container col-xxl-8 px-4 py-5">
                    <div className="row flex-lg-row-reverse align-items-center g-5 py-5">
                        <div className="col-10 col-sm-8 col-lg-6">
                            <h1 id="element-text-type">3-ПЛАСТНО ВЕЛПАПЕ</h1>
                            <img src={image_velpape_3} className="d-block-image-cardboard-boxes mx-lg-auto img-fluid" alt="Image3" width="700" height="500" loading="lazy" />
                        </div>
                        <div className="col-lg-6">
                            <h1 id="material-text-info-title">Три пластно велпапе "Е" вълна</h1>
                            <p className="lead" id="text-custom-description">„Е“ вълна или „микро велпапе“ е с дебелина от 1,5-2,0 мм.
                                Подходящо е за малогабаритни и фини опаковки с по-високи изисквания към печата
                                върху изделията, както и за каширани опаковки.</p>

                            <h2 id="material-text-info-title">Три пластно велпапе "В" вълна</h2>
                            <p className="lead" id="text-custom-description">„В“ вълна с дебелина от 2,5-3,0 мм и е подходящо за средни по размер опаковки.</p>

                            <h2 id="material-text-info-title">Три пластно велпапе "C" вълнa</h2>
                            <p className="lead" id="text-custom-description">„С“ вълна с дебелина от 3,5 – 4,0 мм и е най-широко разпространеният
                                материал за изработка на опаковки от велпапе. Подходящ е както за изработка на слотерни
                                опаковки, така и за щанцови изделия.</p>
                        </div>
                    </div>
                </div>

                <div className="b-example-divider"></div>

                <div className="container col-xxl-8 px-4 py-5">
                    <div className="row flex-lg-row-reverse align-items-center g-5 py-5">
                        <div className="col-10 col-sm-8 col-lg-6">
                            <h1 id="element-text-type">5-ПЛАСТНО ВЕЛПАПЕ</h1>
                            <img src={image_velpape_5} className="d-block-image-cardboard-boxes mx-lg-auto img-fluid" alt="Image5" width="700" height="500" loading="lazy" />
                        </div>
                        <div className="col-lg-6">
                            <h1 id="material-text-info-title">Пет пластно велпапе E+C вълна</h1>
                            <p className="lead" id="text-custom-description">„Е+С“ вълна с дебелина от 5,0 – 5,5 мм,
                                която предлага много добра комбинация между елегантност и здравина на опаковките.
                                С високо качество на печата. Материалът е подходящ за изработка на опаковки подложени на по-големи натоварвания.</p>

                            <h2 id="material-text-info-title">Пет пластно велпапе В+С вълна</h2>
                            <p className="lead" id="text-custom-description">„В+С“ вълна с дебелина от 6,5 – 7,0 мм, която предлага
                                най-голяма здравина на опаковките. Използва се за тежки продукти чувствителни на удар и сътресения.</p>

                        </div>
                    </div>
                </div>

                <div className="b-example-divider"></div>


                <div className="container col-xxl-8 px-4 py-5">
                    <div className="row flex-lg-row-reverse align-items-center g-5 py-5">
                        <div className="col-lg-12">
                            <h1 id="material-text-info-title">Как да направим своята поръчка?</h1>
                            <div className="py-2"></div>
                            <p className="lead" id="text-custom-description">1 - При подаването на поръчка е необходима следната информация: Точните вътрешни размери на опаковката (кашони) или да бъде предоставена мостра.</p>

                            <p className="lead" id="text-custom-description">2 - Вид на материала, от който да бъде произведена опаковката или описание на начина на експлоатация, вида на съхранение,
                                приблизителни килограми в опаковка и начина им на подреждане в склад.</p>

                            <p className="lead" id="text-custom-description">3 - При наличието на печат – дизайн на печата, неговите размери, позиционирането му върху опаковката, цветове (може да бъде разработен при нашия проектантдизайнер)</p>

                            <p className="lead" id="text-custom-description">4 - За щанцови опаковки и изделия освен гореизброеното е необходимо представянето на мостра или разработен дизайн и чертежи на изделието. В случай, че няма такава разработка, то дизайнът, разгъвката и вида може да бъде разработен от нас.</p>
                        </div>


                    </div>
                </div>

                <div className="b-example-divider"></div>








            </div>
        </>

    )
}
