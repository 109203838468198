import React, { Component } from "react";
import "./stylesContacts.css";
import { MdEmail, MdAccessTimeFilled } from 'react-icons/md';
import { ImLocation } from 'react-icons/im';
import { FaPhone } from 'react-icons/fa';
import Firm from "./firmbee-h.jpg";
import NavBar1 from "../NavBar/NavBar";





//Using Inline styles in JSX
var styles1 = {
    border: '0',
    width: '100%',
    height: '350px',
};

var styles2 = {
    border: '0',
    width: '100%',
    height: '550px',
    backgroundImage: `url(${Firm})`
};


class Contacts extends Component {
    render() {
        return (
            <React.Fragment>

                <NavBar1 />


                {/* kontakti snimka */}
                <section className="hero-wrap hero-wrap-2" style={styles2} data-stellar-background-ratio="0.5">
                    <div className="overlay"></div>
                    <div className="container">
                        <div className="row no-gutters slider-text align-items-end">
                            <h1 className="mb-0 bread">СВЪРЖЕТЕ СЕ С НАС</h1>
                        </div>
                    </div>
                </section>





                {/* kontaktna information */}

                <section className="ftco-section">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-12">
                                <div className="wrapper">
                                    <div className="row no-gutters mb-5">

                                        <div className="col-md-5 d-flex align-items-stretch">
                                            <div id="map">
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-3">
                                            <div className="dbox w-100 text-center">
                                                <div className="icon d-flex align-items-center justify-content-center">
                                                    <span className="fa fa-map-marker"> <ImLocation /></span>
                                                </div>
                                                <div className="text">
                                                    <p><strong>Адрес:</strong> ул. „Пловдивска“ 41, 6300 Северна промишлена зона, гр.Хасково</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="dbox w-100 text-center">
                                                <div className="icon d-flex align-items-center justify-content-center">
                                                    <span className="fa fa-phone"><FaPhone /></span>
                                                </div>
                                                <div className="text">
                                                    <p><strong>Тел.:</strong> 038/66 31 76</p>
                                                </div>
                                                <div className="text">
                                                    <p><strong>GSM:</strong> 0878 395 313</p>
                                                </div>
                                                <div className="text">
                                                    <p><strong>GSM:</strong> 0885 395 313</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="dbox w-100 text-center">
                                                <div className="icon d-flex align-items-center justify-content-center">
                                                    <span className="fa fa-paper-plane"><MdEmail /></span>
                                                </div>
                                                <div className="text">
                                                    <p><strong>Email:</strong> markompres@abv.bg</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="dbox w-100 text-center">
                                                <div className="icon d-flex align-items-center justify-content-center">
                                                    <span className="fa fa-globe"><MdAccessTimeFilled /></span>
                                                </div>
                                                <div className="text">
                                                    <p><strong>Работно време:</strong></p>
                                                    <p>Понеделник - Петък</p>
                                                    <p>09:00 - 16:00 </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>





                {/* google map */}

                <section data-aos="fade-up">
                    <iframe style={styles1} src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1483.6820098409844!2d25.549105937960345!3d41.94951560451564!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zNDHCsDU2JzU4LjMiTiAyNcKwMzInNjAuMCJF!5e0!3m2!1sbg!2sbg!4v1617726987650!5m2!1sbg!2sbg"
                        alt="google map" title="GoogleMapView" frameBorder="0" allowFullScreen></iframe>
                </section>

                <br />




                {/* Copyright */}

                <footer id="footer">
                    <div className="container">
                        <div className="copyright">
                            &copy; Авторски права <strong><span>МарКом-МС</span></strong>
                        </div>
                        <div className="credits">
                            Designed by Petar Mirchev
                        </div>
                    </div>
                </footer>



            </React.Fragment >
        );
    }
}

export default Contacts;