import React, { Component } from "react";
import ThreeLayerVelpape from './ThreeLayerVelpape/ThreeLayerVelpape';
import Footer from "../Footer/Footer";
import NavBar1 from "../NavBar/NavBar";


class MaterialsPageMain extends Component {
    render() {
        return (
            <>

                <NavBar1 />

                <ThreeLayerVelpape />

                <Footer />

            </>
        );
    }
}

export default MaterialsPageMain;