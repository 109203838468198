import React, { Component } from "react";
import IntroForUs from "./introForUs";
import "./welcomeStyles.css";
import { Card } from 'react-bootstrap';
import { Link } from "react-router-dom";

import image1 from "./images/albums-5.jpg";
import image2 from "./images/smallBox-1.jpg";
import image3 from "./images/pizzaAndCake.jpg";



class WelcomeReview extends Component {
    render() {
        return (

            <>
                {/* for US info + box anime */}
                <IntroForUs />

                {/*  <!-- Three columns of text begin -->*/}

                <div className="mt-5">
                    <div className="container marketing">

                        {/*  <!-- Three columns of text below the carousel -->*/}
                        <div className="row">
                            <div className="col-lg-4 col-md-6 ">
                                <Card className="card-adjustments">
                                    <div className="zoooom">
                                        <Link className="" to="/bigBox">
                                            <Card.Img className="card-img-top-edit" width="240" height="240" variant="top" src={image1} alt="TransportBox" />
                                        </Link>
                                    </div>
                                    <Card.Body>
                                        <Card.Title id="text-title-props">ТРАНСПОРТНИ ОПАКОВКИ</Card.Title>
                                        <Card.Text id="text-text-card-props">
                                            Тези опаковки са артикулът с най-разнообразни употреби, заради тяхната
                                            функционалност и практичност. Вариациите в дължината, височината и ширината
                                            и печата на тези опаковки ги прави много подходящи за многообразие от
                                            продукти от всички промишлености.
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </div>{/*<!-- /.col-lg-4 -->*/}



                            <div className="col-lg-4 col-md-6">
                                <Card className="card-adjustments">
                                    <div className="zoooom">
                                        <Link className="" to="/smallBox">
                                            <Card.Img className="card-img-top-edit" width="240" height="240" variant="top" src={image2} alt="SmallBox" />
                                        </Link>
                                    </div>
                                    <Card.Body>
                                        <Card.Title id="text-title-props">ЩАНЦОВИ ОПАКОВКИ</Card.Title>
                                        <Card.Text id="text-text-card-props">
                                            При щанцовото изрязване позволява изработката на специфични изделия –
                                            самозакопчващи се кутии, тави, касетки и други сглобяеми опаковки.
                                            Възможности за каширане или до два цвята печат.
                                            За тези опаковки е необходимо изработването на щанца според
                                            изискванията на клиента.
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </div>{/*<!-- /.col-lg-4 -->*/}



                            <div className="col-lg-4 col-md-6">
                                <Card className="card-adjustments">
                                    <div className="zoooom">
                                        <Link className="" to="/pizzaBox">
                                            <Card.Img className="card-img-top-edit" width="240" height="240" variant="top" src={image3} alt="SupportBox" />
                                        </Link>
                                    </div>
                                    <Card.Body>
                                        <Card.Title id="text-title-props">ПОДЛОЖКИ ЗА ПИЦИ, ТОРТИ и др.</Card.Title>
                                        <Card.Text id="text-text-card-props">
                                            Предлагаме  богата гама от подложки за пици, торти, сандвичи , палачинки и други.
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </div>{/*<!-- /.col-lg-4 -->*/}
                        </div>{/*<!-- /.row -->*/}
                    </div>
                </div >
            </>
        );
    }
}

export default WelcomeReview;