import React, { Component } from "react";
import { Route, Routes } from "react-router";
import { BrowserRouter } from "react-router-dom";
import { ChakraProvider } from '@chakra-ui/react';


import MainContent from "./MainContent/MainContent";
import Contacts from "./Contacts/Contacts";
import Products from "./Products/Products";
import Error404page from "./Error404page/404page";
import MaterialsPageMain from "./MaterialsPage/MaterialsPageMain";
import BigBox from "./MainContent/BigBox/BigBox";
import PizzaBox from "./MainContent/PizzaBox/PizzaBox";
import SmallBox from "./MainContent/SmallBox/SmallBox";


// import NavBar1 from "./NavBar/NavBar";
// import Footer from "./Footer/Footer";



export default class App extends Component {
    render() {
        return (
            <ChakraProvider>
                <BrowserRouter>
                    <Routes>
                        <Route path="/" exact element={<MainContent />} />
                        <Route path="/products" exact element={<Products />} />
                        <Route path="/contacts" exact element={<Contacts />} />
                        <Route path="/materials" exact element={<MaterialsPageMain />} />

                        <Route path="/bigBox" exact element={<BigBox />} />
                        <Route path="/smallBox" exact element={<SmallBox />} />
                        <Route path="/pizzaBox" exact element={<PizzaBox />} />

                        <Route path="*" element={<Error404page />} />
                    </Routes>



                </BrowserRouter>
            </ChakraProvider>
        );
    }
}