import React, { Component } from "react";
//import Card from "./Cards/Cards"
import Carousel from "./Carousel/Carousel.js"
import WelcomeReview from "./WelcomeReview/WelcomeReview"
import Footer from "../Footer/Footer";
import NavBar1 from "../NavBar/NavBar";


class MainContent extends Component {
    render() {
        return (
            <React.Fragment>

                <NavBar1 />

                <Carousel />

                <WelcomeReview />


                <Footer />

            </React.Fragment>
        );
    }
}

export default MainContent;