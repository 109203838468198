import { Box, SimpleGrid, Stack, Text } from '@chakra-ui/react'


//import { Link } from '@chakra-ui/react'
import * as React from 'react'
import { FooterHeading } from './FooterHeading'

export const LinkGrid = (props) => (
  <SimpleGrid columns={2} {...props}>
    <Box minW="130px">
      <FooterHeading mb="4" as="samp">Контакти:</FooterHeading>
      <Stack>
        <Text className="">markompres@abv.bg</Text>
        <Text className="">038/ 66 31 76</Text>
        <Text className="">0878 39 53 13 </Text>
        <Text className="">0885 39 53 13 </Text>
      </Stack>
    </Box>

    <Box minW="130px">
      <FooterHeading mb="4" as="samp">Местоположение:</FooterHeading>
      <Stack>
        <Text className="">гр. Хасково</Text>
        <Text className="">ул. Пловдивска 41</Text>
      </Stack>
    </Box>
  </SimpleGrid>
)
