import { ButtonGroup } from '@chakra-ui/react';
// import { IconButton } from '@chakra-ui/react';
import * as React from 'react';
// import { FaFacebookSquare } from 'react-icons/fa';
// import { FaLinkedin, FaTwitter } from 'react-icons/fa';

export const SocialMediaLinks = (props) => (
  <ButtonGroup variant="ghost" color="gray.600" {...props}>
    {/* <IconButton as="a" href="#" aria-label="LinkedIn" icon={<FaLinkedin fontSize="20px" />} /> */}
    {/* <IconButton as="a" href="https://www.facebook.com/%D0%9C%D0%B0%D1%80%D0%9A%D0%BE%D0%BC-%D0%9F%D1%80%D0%BE%D0%B8%D0%B7%D0%B2%D0%BE%D0%B4%D1%81%D1%82%D0%B2%D0%BE-%D0%BD%D0%B0-%D1%82%D1%80%D0%B0%D0%BD%D1%81%D0%BF%D0%BE%D1%80%D1%82%D0%BD%D0%B8-%D0%BE%D0%BF%D0%B0%D0%BA%D0%BE%D0%B2%D0%BA%D0%B8-%D0%BE%D1%82-%D0%B2%D0%B5%D0%BB%D0%BF%D0%B0%D0%BF%D0%B5-108863771691744" target="_blank" rel="nofollow" aria-label="GitHub" icon={<FaFacebookSquare fontSize="20px" />} /> */}
    {/* <IconButton as="a" href="#" aria-label="Twitter" icon={<FaTwitter fontSize="20px" />} /> */}
  </ButtonGroup>
)
