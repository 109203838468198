import React, { Component } from 'react';
import boxGif from "./images/box.gif";
import "./welcomeStyles.css";


class introForUs extends Component {
    render() {
        return (
            <>
                {/* <!-- vtori razdel s tekst i snimka--> */}
                <div className="back-color-info-text-welcome">
                    <div className="container-fluid padding">
                        <div className="row padding">



                            <div className="col-dm-12 col-lg-6">
                                <h1 className="forUS"> ЗА НАС </h1>

                                <h2 className="moreInfo">Повече за МарКом-МС</h2>


                                <p className="more-info-text">Производство на опаковки е основна дейност на фирма „МарКом-МС“.
                                    Нашата производствена база е в гр. Хасково.</p>

                                <p className="more-info-text">Специализирани сме в производство на опаковки от велпапе (вълнообразен картон),
                                    транспортни кашони, рекламни опаковки, кутии за пици и торти, кутии за обувки и други.
                                    Нашите изделия могат да бъдат с високо ниво на
                                    индивидуализация според потребностите и изискванията на конкретния продукт.</p>



                            </div>
                            {/* <!-- snimkata--> */}
                            <div className="col-lg-6">
                                <img src={boxGif} className="img-fluid" alt="MoveBox" />
                            </div>

                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default introForUs;


