import React from "react";
import Footer from "../../Footer/Footer";
import bigBoxImg from "./bigBox-5.jpg";
import ripped from "./ripped-cardboard-3.png";
import "./stylesBigBox.css";
import NavBar1 from "../../NavBar/NavBar";

export default function BigBox() {
    return (

        <>
            <NavBar1 />

            <div className="px-4 pt-5 mt-5 text-center border-bottom">
                <h1 className="display-6 text-center pb-2">ТРАНСПОРТНИ ОПАКОВКИ</h1>
                <div className="col-lg-6 mx-auto">


                    <div className="overflow-hidden" >
                        <div className="container px-5">
                            <img src={bigBoxImg} className="img-fluid border rounded-3 shadow-lg mb-4" alt="Example1" width="700" height="500" loading="eager" />
                        </div>
                    </div>

                    <p className="mb-4">Стандартен кашон с нормално затваряне на клапите - с широка гама приложения.
                        Възможност за печат, три или петпластово велпапе, кафяво или бяло покритие. Върху всички опаковки може да бъде проектиран и
                        разработен флексов печат в различни цветове.  </p>

                </div>
            </div>

            <img src={ripped} className="img-fluid" alt="cardboard" loading="eager" />

            <div className="border-bottom">
            </div>



            < Footer />

        </>

    )
}
